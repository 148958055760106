// jsBridgeInterface.js
'use client';
'use strict';

import {
  getIsOnAndroidWebview,
  getIsOnIOSWebview,
} from '../resource/getUserAgent.js';

if (getIsOnAndroidWebview() || getIsOnIOSWebview()) {
  const getRefreshToken = async () => {
    try {
      const meData = await window.getDbData?.('me');
      return meData?.refreshToken ?? null;
    } catch (_) {
      //
    }
    return null;
  };

  if (window.WebApp && !window.WebApp.getRefreshToken) {
    window.WebApp.getRefreshToken = getRefreshToken;
  } else if (!window.WebApp) {
    window.addEventListener('message', event => {
      if (event.data === 'webAppReady' && window.WebApp) {
        window.WebApp.getRefreshToken = getRefreshToken;
      }
    });
  }
}
